import React from "react";
import "./Technologies.scss";

export default function Technologies() {
    return (
        <div className="technologies-container my1 animate">
            <div className="statement">
                <h1 className="fadeInUp--0">Technologies you can trust</h1>
                <h1 className="fadeInUp--0"></h1>
                <h3 className="fadeInUp--1">We only use well supported and battle tested technologies backed by the biggest companies.</h3>
            </div>
            <div className="tech-company-logos">
                <div className="fadeInUp--0">
                    <div className="image microsoft"><img alt="Microsoft Logo" loading="lazy" src="/Microsoft_logo_(2012).svg"/></div>
                </div>
                <div className="fadeInUp--1">
                    <div className="image amazon"><img alt="Amazon logo" loading="lazy" src="/Amazon_logo.svg"/></div>
                </div>
                <div className="fadeInUp--2">
                    <div className="image google"><img alt="Google Logo" loading="lazy" src="/Google_2015_logo.svg"/></div>
                </div>
                <div className="fadeInUp--3">
                    <div className="image meta"><img alt="Meta Logo" loading="lazy" src="/Meta_Platforms_Inc._logo.svg"/></div>
                </div>
            </div>
        </div>
    );
}