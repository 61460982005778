import React, {useEffect} from "react";
import "./Development.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglass, faPhone, faShield, faShuffle} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {faHandshake} from "@fortawesome/free-regular-svg-icons";
import DigitalTransformation from "../home/sections/digital-transformation/DigitalTransformation";
import {Helmet} from "react-helmet";

export default function Development() {
    const navigate = useNavigate();

    return (
        <div className="services-container">
            <Helmet>
                <title>Bespoke Solution for an Affordable Price | EDBWN Software</title>
                <meta name="description" content="We develop tailored solution for your business needs. Working in partnership with you, we will produce software that is high quality and unique to your organisation."/>
                <meta name="keywords" content="development page, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Bespoke Solution for an Affordable Price | EDBWN Software"/>
                <meta property="og:description" content="We develop tailored solution for your business needs. Working in partnership with you, we will produce software that is high quality and unique to your organisation."/>
                <meta property="og:image" content="https://edbwn.com/dev-680x550.jpg"/>
                <meta property="og:url" content="https://edbwn.com/dev"/>
            </Helmet>
            <section className="main animate">
                <div className="header">
                    <h2 className="fadeInUp--0">Bespoke Software</h2>
                    <h2 className="fadeInUp--0">Development & Support</h2>
                    <div className="text-md fadeInUp--1">
                        We develop tailored solution for your business needs. Working in partnership with you, we will produce software that is high quality and unique to your organisation.
                    </div>
                    <div className="mt4 fadeInUp--2">
                        <button onClick={() => navigate("/contact")} className="primary book-call">
                            <span>Book a Call</span>
                            <FontAwesomeIcon icon={faPhone}/>
                        </button>
                    </div>
                </div>
                <div className="capabilities">
                    <div className="card fadeInUp--0">
                        <div className="content">
                            <div className="title">Software Engineering</div>
                            <div className="sub-title">
                                We solve intricate challenges, unlock unrivaled performance, and prioritize a security-first mindset.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--1">
                        <div className="content">
                            <div className="title">Mobile App Development</div>
                            <div className="sub-title">
                                Unlock the full potential of your app across all devices with native development for Android and iOS.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--2">
                        <div className="content">
                            <div className="title">AI & Machine Learning</div>
                            <div className="sub-title">
                                Release the potential of AI to optimize, learn, and reinvent. AI possesses the capability to elevate your business.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--3">
                        <div className="content">
                            <div className="title">Data</div>
                            <div className="sub-title">
                                Uncover insights with digital transformation, gaining unparalleled access to valuable data.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--4">
                        <div className="content">
                            <div className="title">Cloud</div>
                            <div className="sub-title">
                                Save significantly, access scalability, top-tier security, and tremendous efficiency.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--5">
                        <div className="content">
                            <div className="title">Security</div>
                            <div className="sub-title">
                                We implement a security-first approach, ensuring protection from consultation and development to live release.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="consultation animate">
                <h2 className="fadeInUp--0">Project Planning</h2>
                <div className="mx--20 fadeInUp--1">
                    Our <i><span
                    className="highlight">FREE</span></i> project planning is tailored to transform your concept into a well-defined specification, offering clarity on digital transformation and outlining the project costs.
                </div>
                <div className="description">
                    <div className="image fadeInUp--2">
                        <div className="card">
                            <div className="image single">
                                <picture>
                                    <source media="(max-width: 740px)" srcSet="/consultation-680x550.jpg 740w"/>
                                    <img loading="lazy"
                                         alt="Project Planning"
                                         src="/consultation-300x350.jpg"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="text fadeInUp--3">
                        <h3>Exceptional value with zero cost</h3>
                        <div>
                            We kick off our journey with an introductory call, delving into your needs at a broad level. During this session, we establish key points of contact and determine the optimal date and time to commence our in-depth consultation.
                        </div>
                        <div className="mt4">
                            During our project planning sessions, we'll thoroughly review or create the project specification and generate a comprehensive backlog of tasks. Depending on the project's complexity, multiple sessions may be required to fully scope out all aspects.
                        </div>
                    </div>
                </div>
            </section>
            <section className="dev animate">
                <h2 className="fadeInUp--0">Development</h2>
                <div className="mx--20 fadeInUp--1">
                    Employing contemporary techniques and cutting-edge technologies, we will craft bespoke software meticulously tailored to your business, prioritizing performance, security, and maintainability.
                </div>
                <div className="description">
                    <div className="text fadeInUp--2">
                        <h3>Lets build it together</h3>
                        <div>
                            We operate in two-week development cycles, following agile methodologies. This ensures that every fortnight, you have the opportunity to review our progress, ask questions, and implement any desired changes to align with your evolving needs.
                        </div>
                        <div className="mt4">
                            Throughout the development process, we'll collaborate closely to ensure every aspect aligns seamlessly with your vision. Our aim is to keep the project on target and within budget, making your objectives a priority at every stage.
                        </div>
                        <div className="mt4">
                            As an integral part of our development cycle, each feature undergoes rigorous testing before reaching you. This provides you with the opportunity to ensure that everything functions precisely as you envision, meeting your expectations at every step.
                        </div>
                    </div>
                    <div className="image fadeInUp--3">
                        <div className="card">
                            <div className="image single">
                                <picture>
                                    <source media="(max-width: 740px)" srcSet="/dev-680x550.jpg 740w"/>
                                    <img alt="Bespoke Development" loading="lazy" src="/dev-300x350.jpg"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="support animate">
                <h2 className="fadeInUp--0">Support</h2>
                <div className="mx--30 fadeInUp--1">
                    We offer post-development support, and with the signing of a 12-month contract, the initial 3 months are <i><span
                    className="highlight">FREE</span></i>
                </div>
                <div className="description">
                    <div className="image fadeInUp--2">
                        <div className="card">
                            <div className="image single">
                                <picture>
                                    <source media="(max-width: 740px)" srcSet="/support-680x550.jpg 740w"/>
                                    <img alt="Live Support" loading="lazy" src="/support-300x350.jpg"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="text fadeInUp--3">
                        <h3>We got you covered</h3>
                        <div>
                            After the completion of development, we offer a complimentary 2-week post-release support period to facilitate a seamless transition for your business. During this time, we address any issues and provide assistance in adapting your business to the new solution.
                        </div>
                        <div className="mt4">
                            After the conclusion of the initial 2-week post-release period, you have the choice to enter into a 12-month support contract. Opting for this contract grants you 3 out of the 12 months free of charge. This comprehensive package encompasses system maintenance, upgrades, and timely resolution of any issues.
                        </div>
                    </div>
                </div>
            </section>
            <section className="digital-transformation">
                <DigitalTransformation/>
            </section>
            <section className="working animate">
                <h2 className="fadeInUp--0">How we work</h2>
                <div className="working-cards">
                    <div className="card left fadeInUp--1">
                        <div className="content">
                            <div className="title">
                                <FontAwesomeIcon icon={faHourglass}></FontAwesomeIcon>
                                Fast Delivery
                            </div>
                            <div className="sub-title">
                                We operate in 2-week development cycles, allowing you to see your software in action sooner. Every two weeks, you can review our progress to ensure it aligns perfectly with your vision.
                            </div>
                        </div>
                    </div>
                    <div className="card left fadeInUp--2">
                        <div className="content">
                            <div className="title">
                                <FontAwesomeIcon icon={faHandshake}/>
                                Collaborative Working
                            </div>
                            <div className="sub-title">
                                We collaborate with you to plan, review, and enhance every step of our digital journey, forming a high-performing team to precisely craft the software your business requires.
                            </div>
                        </div>
                    </div>
                    <div className="card left fadeInUp--3">
                        <div className="content">
                            <div className="title">
                                <FontAwesomeIcon icon={faShuffle}/>
                                Flexible Contracts
                            </div>
                            <div className="sub-title">
                                Every aspect of our partnership is customized for your business, catering to your preferences. Whether you opt for a time and materials or fixed budget, we've got you covered. What works for you, works for us.
                            </div>
                        </div>
                    </div>
                    <div className="card left fadeInUp--4">
                        <div className="content">
                            <div className="title">
                                <FontAwesomeIcon icon={faShield}/>
                                Secure Development
                            </div>
                            <div className="sub-title">
                                We prioritize security with a dedicated focus, implementing best practices and a security-first approach in all our endeavors. Employing DevSecOps, we integrate security testing at every stage of our software development process.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact animate">
                <h2 className="fadeInUp--0">Want to know more?</h2>
                <button onClick={() => navigate("/contact")} className="primary book-call fadeInUp--1">
                    <span>Book a Call</span>
                    <FontAwesomeIcon icon={faPhone}/>
                </button>
            </section>
        </div>
    );
}