import React from "react";

export default function Logo({fillColour, width = 130, height = 40}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width}
             zoomAndPan="magnify" viewBox="0 0 97.5 30.000001" height={height} preserveAspectRatio="xMidYMid meet"
             version="1.0">
            <defs>
                <g/>
                <clipPath id="176da35faa">
                    <path d="M 1.332031 24 L 48 24 L 48 28 L 1.332031 28 Z M 1.332031 24 " clipRule="nonzero"/>
                </clipPath>
            </defs>
            <g clipPath="url(#176da35faa)">
                <path strokeLinecap="butt" transform="matrix(0.725806, 0, 0, 0.725806, 1.330644, 24.213274)"
                      fill="none" strokeLinejoin="miter" d="M 0.0019119 2.499386 L 60.925524 2.499386 "
                      stroke={fillColour} strokeWidth="5" strokeOpacity="1" strokeMiterlimit="4"/>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(1.330644, 19.303945)">
                    <g>
                        <path
                            d="M 12.625 -3.0625 L 12.625 0 L 1.390625 0 L 1.390625 -13.984375 L 12.359375 -13.984375 L 12.359375 -10.921875 L 5.3125 -10.921875 L 5.3125 -8.5625 L 11.515625 -8.5625 L 11.515625 -5.609375 L 5.3125 -5.609375 L 5.3125 -3.0625 Z M 12.625 -3.0625 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(16.481196, 19.303945)">
                    <g>
                        <path
                            d="M 1.390625 -13.984375 L 8 -13.984375 C 9.53125 -13.984375 10.890625 -13.695312 12.078125 -13.125 C 13.265625 -12.550781 14.179688 -11.738281 14.828125 -10.6875 C 15.484375 -9.632812 15.8125 -8.398438 15.8125 -6.984375 C 15.8125 -5.578125 15.484375 -4.347656 14.828125 -3.296875 C 14.179688 -2.242188 13.265625 -1.429688 12.078125 -0.859375 C 10.890625 -0.285156 9.53125 0 8 0 L 1.390625 0 Z M 7.84375 -3.15625 C 9.039062 -3.15625 10.003906 -3.492188 10.734375 -4.171875 C 11.460938 -4.847656 11.828125 -5.785156 11.828125 -6.984375 C 11.828125 -8.179688 11.460938 -9.117188 10.734375 -9.796875 C 10.003906 -10.484375 9.039062 -10.828125 7.84375 -10.828125 L 5.359375 -10.828125 L 5.359375 -3.15625 Z M 7.84375 -3.15625 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(34.705722, 19.303945)">
                    <g>
                        <path
                            d="M 12.140625 -7.28125 C 12.941406 -7.007812 13.566406 -6.578125 14.015625 -5.984375 C 14.472656 -5.390625 14.703125 -4.671875 14.703125 -3.828125 C 14.703125 -2.609375 14.207031 -1.664062 13.21875 -1 C 12.226562 -0.332031 10.804688 0 8.953125 0 L 1.390625 0 L 1.390625 -13.984375 L 8.546875 -13.984375 C 10.328125 -13.984375 11.679688 -13.648438 12.609375 -12.984375 C 13.546875 -12.328125 14.015625 -11.441406 14.015625 -10.328125 C 14.015625 -9.660156 13.851562 -9.0625 13.53125 -8.53125 C 13.207031 -8.007812 12.742188 -7.59375 12.140625 -7.28125 Z M 5.3125 -11.125 L 5.3125 -8.421875 L 8.03125 -8.421875 C 9.363281 -8.421875 10.03125 -8.875 10.03125 -9.78125 C 10.03125 -10.675781 9.363281 -11.125 8.03125 -11.125 Z M 8.625 -2.859375 C 10.007812 -2.859375 10.703125 -3.328125 10.703125 -4.265625 C 10.703125 -5.210938 10.007812 -5.6875 8.625 -5.6875 L 5.3125 -5.6875 L 5.3125 -2.859375 Z M 8.625 -2.859375 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(51.79248, 19.303945)">
                    <g>
                        <path
                            d="M 23.328125 -13.984375 L 18.796875 0 L 14.5625 0 L 11.875 -8.5 L 9.078125 0 L 4.859375 0 L 0.3125 -13.984375 L 4.390625 -13.984375 L 7.203125 -5.046875 L 10.171875 -13.984375 L 13.796875 -13.984375 L 16.609375 -4.96875 L 19.546875 -13.984375 Z M 23.328125 -13.984375 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(77.163005, 19.303945)">
                    <g>
                        <path
                            d="M 14.703125 -13.984375 L 14.703125 0 L 11.4375 0 L 5.265625 -7.453125 L 5.265625 0 L 1.390625 0 L 1.390625 -13.984375 L 4.65625 -13.984375 L 10.828125 -6.53125 L 10.828125 -13.984375 Z M 14.703125 -13.984375 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(54.797819, 27.477343)">
                    <g>
                        <path
                            d="M 1.359375 0.03125 C 1.128906 0.03125 0.90625 -0.00390625 0.6875 -0.078125 C 0.46875 -0.148438 0.300781 -0.242188 0.1875 -0.359375 L 0.34375 -0.703125 C 0.457031 -0.597656 0.609375 -0.507812 0.796875 -0.4375 C 0.984375 -0.375 1.171875 -0.34375 1.359375 -0.34375 C 1.617188 -0.34375 1.8125 -0.382812 1.9375 -0.46875 C 2.0625 -0.550781 2.125 -0.664062 2.125 -0.8125 C 2.125 -0.925781 2.085938 -1.015625 2.015625 -1.078125 C 1.953125 -1.140625 1.867188 -1.1875 1.765625 -1.21875 C 1.671875 -1.257812 1.53125 -1.300781 1.34375 -1.34375 C 1.101562 -1.40625 0.910156 -1.460938 0.765625 -1.515625 C 0.628906 -1.566406 0.507812 -1.648438 0.40625 -1.765625 C 0.300781 -1.890625 0.25 -2.050781 0.25 -2.25 C 0.25 -2.414062 0.289062 -2.5625 0.375 -2.6875 C 0.46875 -2.820312 0.601562 -2.925781 0.78125 -3 C 0.957031 -3.082031 1.175781 -3.125 1.4375 -3.125 C 1.625 -3.125 1.804688 -3.097656 1.984375 -3.046875 C 2.160156 -3.003906 2.3125 -2.941406 2.4375 -2.859375 L 2.296875 -2.5 C 2.160156 -2.582031 2.019531 -2.644531 1.875 -2.6875 C 1.726562 -2.726562 1.582031 -2.75 1.4375 -2.75 C 1.1875 -2.75 1 -2.703125 0.875 -2.609375 C 0.75 -2.523438 0.6875 -2.410156 0.6875 -2.265625 C 0.6875 -2.160156 0.722656 -2.070312 0.796875 -2 C 0.867188 -1.9375 0.957031 -1.882812 1.0625 -1.84375 C 1.164062 -1.8125 1.304688 -1.773438 1.484375 -1.734375 C 1.710938 -1.679688 1.898438 -1.625 2.046875 -1.5625 C 2.191406 -1.507812 2.3125 -1.425781 2.40625 -1.3125 C 2.507812 -1.195312 2.5625 -1.039062 2.5625 -0.84375 C 2.5625 -0.675781 2.515625 -0.523438 2.421875 -0.390625 C 2.335938 -0.265625 2.207031 -0.160156 2.03125 -0.078125 C 1.851562 -0.00390625 1.628906 0.03125 1.359375 0.03125 Z M 1.359375 0.03125 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(58.63802, 27.477343)">
                    <g>
                        <path
                            d="M 1.859375 0.03125 C 1.546875 0.03125 1.265625 -0.0351562 1.015625 -0.171875 C 0.765625 -0.304688 0.566406 -0.492188 0.421875 -0.734375 C 0.285156 -0.972656 0.21875 -1.242188 0.21875 -1.546875 C 0.21875 -1.847656 0.285156 -2.117188 0.421875 -2.359375 C 0.566406 -2.597656 0.765625 -2.785156 1.015625 -2.921875 C 1.265625 -3.054688 1.546875 -3.125 1.859375 -3.125 C 2.171875 -3.125 2.453125 -3.054688 2.703125 -2.921875 C 2.953125 -2.785156 3.144531 -2.597656 3.28125 -2.359375 C 3.425781 -2.117188 3.5 -1.847656 3.5 -1.546875 C 3.5 -1.242188 3.425781 -0.972656 3.28125 -0.734375 C 3.144531 -0.492188 2.953125 -0.304688 2.703125 -0.171875 C 2.453125 -0.0351562 2.171875 0.03125 1.859375 0.03125 Z M 1.859375 -0.359375 C 2.085938 -0.359375 2.289062 -0.410156 2.46875 -0.515625 C 2.65625 -0.617188 2.800781 -0.757812 2.90625 -0.9375 C 3.007812 -1.125 3.0625 -1.328125 3.0625 -1.546875 C 3.0625 -1.773438 3.007812 -1.976562 2.90625 -2.15625 C 2.800781 -2.332031 2.65625 -2.472656 2.46875 -2.578125 C 2.289062 -2.679688 2.085938 -2.734375 1.859375 -2.734375 C 1.628906 -2.734375 1.421875 -2.679688 1.234375 -2.578125 C 1.054688 -2.472656 0.914062 -2.332031 0.8125 -2.15625 C 0.707031 -1.976562 0.65625 -1.773438 0.65625 -1.546875 C 0.65625 -1.328125 0.707031 -1.125 0.8125 -0.9375 C 0.914062 -0.757812 1.054688 -0.617188 1.234375 -0.515625 C 1.421875 -0.410156 1.628906 -0.359375 1.859375 -0.359375 Z M 1.859375 -0.359375 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(63.445984, 27.477343)">
                    <g>
                        <path
                            d="M 0.90625 -2.703125 L 0.90625 -1.625 L 2.453125 -1.625 L 2.453125 -1.25 L 0.90625 -1.25 L 0.90625 0 L 0.46875 0 L 0.46875 -3.09375 L 2.65625 -3.09375 L 2.65625 -2.703125 Z M 0.90625 -2.703125 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(67.348053, 27.477343)">
                    <g>
                        <path
                            d="M 1.078125 -2.703125 L 0.015625 -2.703125 L 0.015625 -3.09375 L 2.578125 -3.09375 L 2.578125 -2.703125 L 1.515625 -2.703125 L 1.515625 0 L 1.078125 0 Z M 1.078125 -2.703125 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(71.038009, 27.477343)">
                    <g>
                        <path
                            d="M 4.84375 -3.09375 L 3.796875 0 L 3.34375 0 L 2.5 -2.46875 L 1.65625 0 L 1.171875 0 L 0.140625 -3.09375 L 0.59375 -3.09375 L 1.4375 -0.578125 L 2.296875 -3.09375 L 2.703125 -3.09375 L 3.5625 -0.5625 L 4.421875 -3.09375 Z M 4.84375 -3.09375 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(77.109803, 27.477343)">
                    <g>
                        <path
                            d="M 2.4375 -0.78125 L 0.796875 -0.78125 L 0.453125 0 L 0 0 L 1.390625 -3.09375 L 1.828125 -3.09375 L 3.234375 0 L 2.78125 0 Z M 2.28125 -1.125 L 1.609375 -2.640625 L 0.953125 -1.125 Z M 2.28125 -1.125 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(81.440514, 27.477343)">
                    <g>
                        <path
                            d="M 2.53125 0 L 1.859375 -0.953125 C 1.785156 -0.941406 1.722656 -0.9375 1.671875 -0.9375 L 0.90625 -0.9375 L 0.90625 0 L 0.46875 0 L 0.46875 -3.09375 L 1.671875 -3.09375 C 2.066406 -3.09375 2.378906 -2.992188 2.609375 -2.796875 C 2.847656 -2.609375 2.96875 -2.347656 2.96875 -2.015625 C 2.96875 -1.773438 2.90625 -1.570312 2.78125 -1.40625 C 2.664062 -1.238281 2.5 -1.117188 2.28125 -1.046875 L 3.015625 0 Z M 1.65625 -1.3125 C 1.9375 -1.3125 2.148438 -1.375 2.296875 -1.5 C 2.441406 -1.625 2.515625 -1.796875 2.515625 -2.015625 C 2.515625 -2.242188 2.441406 -2.414062 2.296875 -2.53125 C 2.148438 -2.644531 1.9375 -2.703125 1.65625 -2.703125 L 0.90625 -2.703125 L 0.90625 -1.3125 Z M 1.65625 -1.3125 "/>
                    </g>
                </g>
            </g>
            <g fill={fillColour} fillOpacity="1">
                <g transform="translate(85.74913, 27.477343)">
                    <g>
                        <path
                            d="M 2.703125 -0.390625 L 2.703125 0 L 0.46875 0 L 0.46875 -3.09375 L 2.65625 -3.09375 L 2.65625 -2.703125 L 0.90625 -2.703125 L 0.90625 -1.765625 L 2.453125 -1.765625 L 2.453125 -1.390625 L 0.90625 -1.390625 L 0.90625 -0.390625 Z M 2.703125 -0.390625 "/>
                    </g>
                </g>
            </g>
        </svg>
    );
}