import Validators from "../utilities/validators";
import {useState} from "react";

class FormControl {
    label = "";
    value = "";
    hasErrors = false;
    errors = [];
    isRequired = false;

    constructor(label, value, required) {
        this.label = label;
        this.value = value;
        this.hasErrors = false;
        this.errors = [];
        this.isRequired = required;
    }

    getError() {
        if (this.errors.length === 0) {
            return null;
        }

        let error = this.errors[0];
        error = error.replace("{label}", this.label);
        error = error.replace("{value}", this.value);
        return error;
    }
}

export default function useFormControl(label, defaultValue, validators = []) {
    const hasRequired = validators.some(function (element) {
        return element === Validators.required;
    });

    const [form, setForm] = useState(new FormControl(label, defaultValue, hasRequired));

    const onChange = (value) => {
        const errors = [];
        validators.forEach(validator => {
            const error = validator(value);

            if (error != null) {
                errors.push(error);
            }
        });

        const newForm = {
            label: form.label,
            value,
            hasErrors: errors.length > 0,
            errors,
            isRequired: form.isRequired,
            getError: form.getError
        };

        setForm(newForm);

        return errors.length > 0;
    }

    return [form, onChange];
}