import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

const resize = () => {
    const heightRatio = Math.min(1, document.documentElement.clientHeight / 760);
    const widthRatio = Math.min(1, document.documentElement.clientWidth / 1200);
    let ratio = Math.min(widthRatio, heightRatio);
    ratio = Math.max(0.8, ratio);
    document.documentElement.style.setProperty("--height-ratio", ratio);
}

resize();
window.addEventListener("resize", (event) => {
    resize();
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
