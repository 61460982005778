import React, {useEffect} from "react";
import "./AboutUs.scss";
import {Helmet} from "react-helmet";

export default function AboutUs() {    
    return (
        <div className="about-container">
            <Helmet>
                <title>Software done the right way | EDBWN Software</title>
                <meta name="description" content="We specialize in facilitating organizations with their digital transformation, offering customized consultation sessions for your development teams and developing bespoke software projects."/>
                <meta name="keywords" content="about, about us, about us page, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Software done the right way | EDBWN Software"/>
                <meta property="og:description" content="We specialize in facilitating organizations with their digital transformation, offering customized consultation sessions for your development teams and developing bespoke software projects."/>
                <meta property="og:image" content="https://edbwn.com/edbwn-600x200.png"/>
                <meta property="og:url" content="https://edbwn.com/about-us"/>
            </Helmet>
            <section className="main animate">
                <div className="header mt1">
                    <h1 className="fadeInUp--0">About Us</h1>
                    <div className="text-md mt4 fadeInUp--1">
                        We specialize in facilitating organizations with their digital transformation, offering customized consultation sessions for your development teams and developing bespoke software projects.
                    </div>
                    <div className="text-md mt4 fadeInUp--2">
                        We are dedicated to aiding individuals and organizations in realizing their goals.
                    </div>
                    <div className="startup-text fadeInUp--3">
                        <h5>
                            Ed Brown
                        </h5>
                        <div className="italic text-sm">
                            Founder & Principal Engineer of EDBWN Software
                        </div>
                        <div className="text-md">
                            I founded EDBWN Software with the aim to aid individuals and organisations in realizing their goals. Be transparent about everything we do and truly work in collaboration with all our partners.
                        </div>
                    </div>
                </div>
                <div className="image-section">
                    <div className="card fadeInUp--2">
                        <div className="image single">
                            <picture>
                                <source media="(max-width: 720px)" srcSet="/edbwn-600x200.png 720w"/>
                                <img alt="EDBWN Software Logo" loading="lazy" src="/edbwn-450x500.png"/>
                            </picture>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision animate">
                <div className="vision-list mt3 mx3">
                    <div className="card fadeInUp--0">
                    <div className="content">
                            <h5 className="title">
                                Transparency
                            </h5>
                            <div className="details">
                                We firmly believe that transparency is the foundation of good business.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--1">
                        <div className="content">
                            <div className="title">
                                Collaboration
                            </div>
                            <div className="details">
                                We excel when we collaborate as a team.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--2">
                        <div className="content">
                            <div className="title">
                                Respect
                            </div>
                            <div className="details">
                                We believe in treating others the way we want to be treated ourselves.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vision-statement">
                    <div className="header">
                        <h2 className="fadeInUp--0">
                            Our Vision
                        </h2>
                    </div>
                    <div className="vision-text mt4">
                        <div className="fadeInUp--1">
                            Is to be the best workplace and business partner through transparency, collaboration and respect
                        </div>
                        <h3 className="fadeInUp--2 my5">Workplace</h3>
                        <div className="fadeInUp--2">
                            Here are EDBWN Software, our dream is to create a workplace where individuals feel valued and can thrive. Prioritizing our people is our key philosophy, believing that when empowered, mentored, and elevated, everyone can reach their fullest potential.
                        </div>
                        <h3 className="fadeInUp--3 my5">Business Partner</h3>
                        <div className="fadeInUp--3">
                            Our goal extends beyond delivering excellent service; we strive to cultivate strong working relationships. Collaboration is our strength, and we believe in treating everyone with respect while maintaining transparency in all aspects of our business.
                        </div>
                    </div>
                </div>
            </section>
            <section className="values animate">
                <div className="text-center">
                    <h2 className="fadeInUp--0">Our Values</h2>
                    <div className="fadeInUp--1">
                        When partnering with us, anticipate these six values to guide every aspect of our work.
                    </div>
                </div>
                <div className="values-list">
                    <div className="card fadeInUp--2">
                        <div className="content">
                            <div className="title">
                                Transparency
                            </div>
                            <div className="details">
                                We uphold honesty in all our actions and decisions.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--3">
                        <div className="content">
                            <div className="title">
                                Collaboration
                            </div>
                            <div className="details">
                                Our optimal performance is achieved through teamwork.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--4">
                        <div className="content">
                            <div className="title">
                                Respect
                            </div>
                            <div className="details">
                                We treat others the way we want to be treated ourselves
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--5">
                        <div className="content">
                            <div className="title">
                                Hard Work
                            </div>
                            <div className="details">
                                We dedicate ourselves to excellence in every endeavor.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--6">
                        <div className="content">
                            <div className="title">
                                Innovation
                            </div>
                            <div className="details">
                                We have a passion for technology and continuously seek improved ways of doing things.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--7">
                        <div className="content">
                            <div className="title">
                                Building Relationships
                            </div>
                            <div className="details">
                                We consider a strong working relationship as the cornerstone for delivering exceptional service.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}