import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export default function Terms() {    
    return (
        <div className="terms-container animate">
            <Helmet>
                <title>Our Terms | EDBWN Software</title>
                <meta name="description" content="EDBWN Software Website Terms"/>
                <meta name="keywords" content="terms, terms page, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Our Terms | EDBWN Software"/>
                <meta property="og:description" content="EDBWN Software Website Terms"/>
                <meta property="og:image" content="https://edbwn.com/white.png"/>
                <meta property="og:url" content="https://edbwn.com/terms"/>
            </Helmet>
            <div className="header mb1 text-center">
                <h1 className="fadeInUp--0">Terms of Use</h1>
            </div>

            <div className="card fadeInUp--1">
                <div className="content">
                    <p>
                        These terms of use ("Terms") govern your access to and use of www.edbwn.com (the "Website") operated by EDBWN Limited (the "Company"). By using the Website, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Website.
                    </p>
                    <ol>
                        <li>
                            <div><b>Acceptance of Terms</b></div>
                            <ol type="a">
                                <li>
                                    By accessing and using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you are using the Website on behalf of an organization, you represent and warrant that you have the authority to bind the organization to these Terms.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Consultancy Services</b></div>
                            <ol type="a">
                                <li>
                                    The Website provides information about the software consultancy services offered by the Company. The information provided on the Website is for general informational purposes only and does not constitute professional advice.
                                </li>
                                <li>
                                    Any engagement for software consultancy services with the Company shall be governed by a separate agreement between the Company and the client.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Intellectual Property</b></div>
                            <ol type="a">
                                <li>
                                    The Website and all its content, including but not limited to text, graphics, images, logos, and software, are the property of the Company or its licensors and are protected by intellectual property laws.
                                </li>
                                <li>
                                    You may not use, reproduce, distribute, modify, or create derivative works of the Website or its content without prior written consent from the Company.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>User Responsibilities</b></div>
                            <ol type="a">
                                <li>
                                    You agree to use the Website in compliance with all applicable laws, regulations, and these Terms.
                                </li>
                                <li>
                                    The information you provide through the Website, such as contact details or project requirements, should be accurate and complete.
                                </li>
                                <li>
                                    You are responsible for maintaining the confidentiality of any account credentials and for all activities that occur under your account.
                                </li>
                                <li>
                                    You agree not to engage in any activity that may interfere with or disrupt the functioning of the Website or its associated servers.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Limitation of Liability</b></div>
                            <ol type="a">
                                <li>
                                    The Company shall not be liable for any damages, whether direct, indirect, incidental, consequential, or special, arising out of or in connection with the use or inability to use the Website or the information provided on the Website.
                                </li>
                                <li>
                                    The Company does not warrant the accuracy, completeness, or reliability of the information provided on the Website.
                                </li>
                                <li>
                                    The Company shall not be responsible for any losses or damages resulting from any third-party websites or resources linked to or from the Website.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Modification and Termination</b></div>
                            <ol type="a">
                                <li>
                                    The Company reserves the right to modify, suspend, or terminate the Website or any part thereof, at any time and without prior notice.
                                </li>
                                <li>
                                    The Company may also modify these Terms from time to time, and any such modifications will be effective upon posting on the Website.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Governing Law and Jurisdiction</b></div>
                            <ol type="a">
                                <li>
                                    These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these Terms or the use of the Website shall be subject to the exclusive jurisdiction of the courts of England and Wales.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}