import React from "react";
import CreateGuid from "../../utilities/guid";

export default function Input({type, placeholder, form, setValue}) {
    const id = CreateGuid();

    if (!form) {
        return <></>;
    }

    let errorTemplate = <></>;
    if (form.hasErrors) {
        errorTemplate = <div className="error">{form.getError()}</div>;
    }

    const handleChange = ({target}) => {
        setValue(target.value);
    }
    
    if(type === "textarea") {
        return (
            <>
                <label htmlFor={id}>{form.label}{form.isRequired ? "*" : ""}</label>
                <textarea
                    id={id}
                    placeholder={placeholder}
                    className="input"
                    value={form.value}
                    onChange={handleChange}
                    rows="8"
                />
                <div className="error">{errorTemplate}</div>
            </>
        );
    }

    return (
        <>
            <label htmlFor={id}>{form.label}{form.isRequired ? "*" : ""}</label>
            <input
                id={id}
                placeholder={placeholder}
                className="input"
                value={form.value}
                onChange={handleChange}
                type={type}
            />
            {errorTemplate}
        </>
    );
}