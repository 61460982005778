import React from "react";
import "./Header.scss";
import Logo from "../../assets/logos/Logo";
import Menu from "./Menu";

export default function Header({scrolled = false}) {
    let colour = '#000000';
    let classes = '';
    if (scrolled) {
        colour = '#ffffff';
        classes = ' scrolled';
    }

    return (
        <>
            <div className={"header-container" + classes}>
                <div>
                    <Logo fillColour={colour} width={220} height={68}/>
                </div>
                <div className="menu-container">
                    <Menu scrolled={scrolled}/>
                </div>
            </div>
        </>
    );
}