import React, {useEffect} from "react";
import "./Consultation.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Consultation() {
    const navigate = useNavigate();

    return (
        <div className="consultation-section">
            <Helmet>
                <title>Supercharge your Development Team | EDBWN Software</title>
                <meta name="description" content="We're here to enhance and optimize your software team. Are your projects consistently missing deadlines and exceeding budgets? Let's fix that."/>
                <meta name="keywords" content="development page, software, cnsultation, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Supercharge your Development Team | EDBWN Software"/>
                <meta property="og:description" content="We're here to enhance and optimize your software team. Are your projects consistently missing deadlines and exceeding budgets? Let's fix that."/>
                <meta property="og:image" content="https://edbwn.com/team-goal.svg"/>
                <meta property="og:url" content="https://edbwn.com/consultation"/>
            </Helmet>
            <section className="main animate">
                <div className="header">
                    <h1 className="fadeInUp--0">Improve, optimize and</h1>
                    <h1 className="fadeInUp--0">upskill your Software team</h1>
                    <div className="text-md mx--30 fadeInUp--1">
                        We're here to enhance and optimize your software team. Are your projects consistently missing deadlines and exceeding budgets? Let's fix that.
                    </div>
                    <div className="my--8 fadeInUp--2">
                        <button onClick={() => navigate("/contact")} className="primary book-call">
                            <span>Book a Call</span>
                            <FontAwesomeIcon icon={faPhone}/>
                        </button>
                    </div>
                </div>
            </section>
            <section className="services animate">
                <div className="fadeInUp--1">
                    <h5>Tailored</h5>
                    <div>
                        We'll familiarize ourselves with your organization and customize all our services to suit your business.
                    </div>
                </div>
                <div className="fadeInUp--2">
                    <h5>Technical</h5>
                    <div>
                        We'll leverage our technical expertise to steer your organization towards technical excellence.
                    </div>
                </div>
                <div className="fadeInUp--3">
                    <h5>Transformative</h5>
                    <div>
                        We'll empower your software team to gain confidence, ambition, and exceed expectations.
                    </div>
                </div>
                <div className="fadeInUp--4">
                    <h5>Training</h5>
                    <div>
                        We offer software delivery training sessions for project managers, developers, and testers.
                    </div>
                </div>
            </section>
            <section className="fast-delivery mt1 animate">
                <h2 className="fadeInUp--1">Real Results</h2>
                <div className="fast-delivery-container mt3">
                    <div className="text animate">
                        <div className="fadeInUp--1">
                            <h4>Fast Delivery</h4>
                            <div className="result">
                                We'll analyze your process, from gathering specifications to releasing individual features into a live system, and provide a comprehensive report outlining potential improvements aligned with industry best practices. 
                            </div>
                        </div>
                        <div className="fadeInUp--2">
                            <h4>Clear Goals</h4>
                            <div className="result">
                                We'll conduct an in-depth analysis of specific specifications and how they're presented to developers, offering insights and recommendations to optimize developer efficiency and focus.
                            </div>
                        </div>
                        <div className="fadeInUp--3">
                            <h4>High Quality</h4>
                            <div className="result">
                                Empowering your developers with support, guidance, and training to enhance their skills and elevate the quality of their work. We streamline repetitive tasks through automation and promote automated testing for efficiency and reliability.
                            </div>
                        </div>
                    </div>
                    <div className="image animate">
                        <img alt="Elevate your development team" className="fadeInUp--4" src="/team-goal.svg" />
                    </div>
                </div>
            </section>
            <section className="technology-partners mt1 animate">
                <h2 className="fadeInUp--1">Technology Experience</h2>
                <div className="fadeInUp--2 text-center mt5 mx--20">
                    We bring extensive experience with leading-edge technologies, adhering to industry standards and utilizing battle-tested solutions from top-tier companies.
                </div>
                <div className="technology-container mt4">
                    <div className="card fadeInUp--1">
                        <div className="image single">
                            <img alt="Microsoft Logo" src="/Microsoft_logo_(2012).svg"/>
                        </div>
                    </div>
                    <div className="card fadeInUp--2">
                        <div className="image single">
                            <img alt="Google Logo" src="/Google_2015_logo.svg"/>
                        </div>
                    </div>
                    <div className="card fadeInUp--3">
                        <div className="image single">
                            <img alt="Amazon Logo" src="/Amazon_logo.svg"/>
                        </div>
                    </div>
                    <div className="card fadeInUp--4">
                        <div className="image single">
                            <img alt="Meta Logo" src="/Meta_Platforms_Inc._logo.svg"/>
                        </div>
                    </div>
                    <div className="card fadeInUp--5">
                        <div className="image single">
                            <img alt="Open AI Logo" src="/OpenAI_Logo.svg"/>
                        </div>
                    </div>
                    <div className="card fadeInUp--6">
                        <div className="image single">
                            <img alt="BogCommerce Logo" src="/bigcommerce-ar21.svg"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="expectation mt1 animate">
                <h2 className="fadeInUp--1">What to Expect</h2>
                <div className="expectation-container mt3 animate">
                <div className="card fadeInUp--1">
                        <div className="content">
                        <div className="title">
                                Grasping the objective.
                            </div>
                            <div className="details">
                                We'll conduct interviews with you, the managers, and the development team to understand their objectives and ensure alignment. Additionally, we'll observe their day-to-day tasks and interactions among different team roles.
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--2">
                        <div className="content">
                            <div className="title">
                                Recognizing the challenges.
                            </div>
                            <div className="details">
                                Once we grasp the team dynamics and your employees daily processes, we'll identify any potential issues and produce a detailed report documenting our findings. 
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--3">
                        <div className="content">
                            <div className="title">
                                Formulating a strategy.
                            </div>
                            <div className="details">
                                In the same report, we'll outline a list of improvements along with their respective difficulty levels, enabling you to initiate departmental enhancements promptly. 
                            </div>
                        </div>
                    </div>
                    <div className="card fadeInUp--4">
                        <div className="content">
                            <div className="title">
                                Improve, optimize and upskill
                            </div>
                            <div className="details">
                                We'll stand by you throughout your journey, offering continuous support, advice, and necessary training to help you achieve your goals.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact my--20 animate">
                <h2 className="mb2 fadeInUp--0">Want to know more?</h2>
                <button onClick={() => navigate("/contact")} className="primary book-call fadeInUp--1">
                    <span>Book a Call</span>
                    <FontAwesomeIcon icon={faPhone}/>
                </button>
            </section>
        </div>
    );
}