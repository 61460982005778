import React from "react";
import "./BlogDetails.scss";
import {useNavigate, useParams} from "react-router-dom";
import Markdown from "react-markdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import useBlog from "../../hooks/useBlog";
import Animations from "../../components/scroll-top/Animations";
import {Helmet} from "react-helmet";
import remarkGfm from "remark-gfm";

export default function BlogDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [blog, loading] = useBlog(params.url);

    if (loading) {
        return (
            <div className="blog-container">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="blog-container animate">
            <Helmet>
                <title>{`${blog.title} | EDBWN Software`}</title>
                <meta name="description" content={blog.description}/>
                <meta name="keywords" content={`${blog.title}, blog, blog page, software, development, support, bespoke, project, it, edbwn, edbwn software`}/>
                <meta property="og:title" content={blog.title}/>
                <meta property="og:description" content={blog.description}/>
                <meta property="og:image" content={`https://edbwn.com${blog.imageLarge}`}/>
                <meta property="og:url" content={`https://edbwn.com/blog/${blog.url}`}/>
            </Helmet>
            <div className="title fadeInUp--0">
                <h1>
                    {blog.title}
                </h1>
                <div className="date-time">
                    <span>{new Date(blog.createdAt).toDateString("en-GB")} &bull; {blog.readTime}</span>
                </div>
            </div>
            <div className="card fadeInUp--1">
                <div className="image full">
                    <img src={blog.imageLarge}/>
                </div>
            </div>
            <section className="blog-details mx--20 fadeInUp--2">
                <div className="author mb4">
                    <div><b>By {blog.byWho}</b></div>
                </div>

                <Markdown remarkPlugins={[remarkGfm]}>{blog.blogBody}</Markdown>

                <div className="author right mt4">
                    <div><b>{blog.byWho}</b></div>
                    <div><b>{blog.personTitle}</b></div>
                </div>
            </section>
            <section className="contact animate">
                <h2 className="fadeInUp--0">Get in touch!</h2>
                <button onClick={() => navigate("/contact")} className="primary book-call fadeInUp--1">
                    <span>Book a Call</span>
                    <FontAwesomeIcon icon={faPhone}/>
                </button>
            </section>
            <Animations></Animations>
        </div>
    );
}