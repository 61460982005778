import React from "react";
import "./Main.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from "react-router-dom";

export default function Main() {
    const navigate = useNavigate();
    
    return (
        <>
            <div className="main-container animate">
                <div className="main-text">
                    <h1 className="fadeInUp--0">Helping Unleash Your</h1>
                    <h1 className="fadeInUp--0">Digital Potential</h1>
                    <div className="mission">
                        <h3 className="fadeInUp--1">Our mission is to improve, optimize and empower</h3>
                        <h3 className="fadeInUp--1">organisations through technology.</h3>
                    </div>
                    <div className="mission-mobile">
                        <h3 className="fadeInUp--1">Our mission is to improve, optimize and empower organisations through technology.</h3>
                    </div>
                    <div className="fadeInUp--2">
                        <button onClick={() => navigate("/contact")} className="primary book-call mt2">
                            <span>Book a Call</span>
                            <FontAwesomeIcon icon={faPhone}/>
                        </button>
                    </div>
                </div>
                <div className="image-container">
                    <div className="front--3 fadeInUp--3"><img alt="Image of Code" loading="lazy" className="manufacturing" src="/code.jpg"/></div>
                    <div className="front--2 fadeInUp--1"><img alt="Image of working together" loading="lazy" className="code" src="/together.jpg"/></div>
                    <div className="front--1 fadeInUp--2"><img alt="Image of a kanban board" loading="lazy" className="tasks" src="/kanban.jpg"/></div>
                </div>
            </div>
        </>
    );
}