import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export default function Privacy() {    
    return (
        <div className="privacy-container animate">
            <Helmet>
                <title>Our Privacy | EDBWN Software</title>
                <meta name="description" content="EDBWN Software Website Privacy"/>
                <meta name="keywords" content="privacy, privacy page, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Our Privacy | EDBWN Software"/>
                <meta property="og:description" content="EDBWN Software Website Privacy"/>
                <meta property="og:image" content="https://edbwn.com/white.png"/>
                <meta property="og:url" content="https://edbwn.com/privacy"/>
            </Helmet>
            <div className="header mb1 text-center">
                <h1 className="fadeInUp--0">Privacy & Cookies</h1>
            </div>

            <div className="card fadeInUp--1">
                <div className="content">
                    <p>
                        This Privacy Policy ("Policy") describes how EDBWN Limited ("we," "us," or "our") collects, uses, and shares personal information when you use our website www.edbwn.com (the "Website") and engage with our software consultancy services. By using the Website and engaging with our services, you agree to the collection and use of your personal information as described in this Policy.
                    </p>
                    <ol>
                        <li>
                            <div><b>Information We Collect</b></div>
                            <ol type="a">
                                <li>
                                    Personal Information: We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, job title, and any other information you provide when contacting us or engaging with our services.
                                </li>
                                <li>
                                    Automatically Collected Information: When you visit the Website, we may automatically collect certain information, such as your IP address, browser type, operating system, referring URLs, and other usage information.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Use of Information</b></div>
                            <ol type="a">
                                <li>
                                    We may use the personal information we collect to:
                                    <ul>
                                        <li>
                                            Provide and deliver our software consultancy services to you.
                                        </li>
                                        <li>
                                            Respond to your inquiries and communicate with you about our services.
                                        </li>
                                        <li>
                                            Send you updates, newsletters, and marketing materials related to our services, with your consent.
                                        </li>
                                        <li>
                                            Improve and personalize our services and the user experience of the Website.
                                        </li>
                                        <li>
                                            Protect the security and integrity of our systems and services.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    We will only process your personal information for the purposes mentioned in this Policy or as otherwise notified to you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Sharing of Information</b></div>
                            <ol type="a">
                                <li>
                                    We may share your personal information with trusted third parties who assist us in providing our software consultancy services or managing the Website. These third parties are obligated to maintain the confidentiality of your information.
                                </li>
                                <li>
                                    We may also disclose your personal information if required by law, regulation, legal process, or governmental request, or to protect our rights, property, or safety.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Cookies and Tracking Technologies</b></div>
                            <ol type="a">
                                <li>
                                    We may use cookies, web beacons, and similar tracking technologies to collect information about your interactions with the Website and to enhance your user experience.
                                </li>
                                <li>
                                    You can control cookies through your browser settings. However, please note that disabling cookies may affect the functionality of the Website.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Data Retention</b></div>
                            <ol type="a">
                                <li>
                                    We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Security</b></div>
                            <ol type="a">
                                <li>
                                    We take reasonable measures to protect your personal information from unauthorized access, loss, misuse, or alteration. However, please be aware that no security measure is perfect, and we cannot guarantee the absolute security of your information.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Your Rights</b></div>
                            <ol type="a">
                                <li>
                                    You have the right to access, correct, and delete your personal information held by us. You may also have the right to restrict or object to certain processing activities. Please contact us using the information provided below to exercise these rights.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Third-Party Links</b></div>
                            <ol type="a">
                                <li>
                                    The Website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites. We encourage you to review the privacy policies of those websites.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Children's Privacy</b></div>
                            <ol type="a">
                                <li>
                                    The Website and our services are not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove the information from our systems.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Changes to the Policy</b></div>
                            <ol type="a">
                                <li>
                                    We reserve the right to update or modify this Policy at any time. Any changes will be effective when posted on the Website. We encourage you to review this Policy periodically for any updates.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <div><b>Contact Us</b></div>
                            <ol type="a">
                                <li>
                                    If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at <a
                                    href="mailto:contact@edbwn.com">contact@edbwn.com</a>.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}