export default class Validators {
    static required = function (value) {
        if (value == null || value === "") {
            return "{label} is Required."
        }

        return null;
    }

    static email = function (value) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailPattern.test(value)) {
            return null;
        } else {
            return "{value} is not a valid Email Address";
        }
    }

    static phone = function (number) {
        const numberPattern = /^(\+)?(?:\(\d+\)|\d+)(?:\s(?:\(\d+\)|\d+))*$/;
        const strippedNumber = number.replace(/[\s\+\(\)]/g, '');

        if (numberPattern.test(strippedNumber)) {
            return null;
        } else {
            return "{value} is not a valid Phone Number";
        }
    }
}