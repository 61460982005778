import React, {useEffect} from "react";
import Main from "./sections/main/Main";
import Trusted from "./sections/trusted/Trusted";
import Technologies from "./sections/technologies/Technologies";
import Blogs from "./sections/blogs/Blogs";
import Services from "./sections/services/Services";
import {Helmet} from "react-helmet";

export default function Home() {    
    return (
        <>
            <Helmet>
                <title>Software Consultation, Development and Support | EDBWN Software</title>
                <meta name="description" content="We specialize in facilitating organizations with their digital transformation, offering customized consultation sessions for your development teams and developing bespoke software projects."/>
                <meta name="keywords" content="home page, software, consultation, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Software Consultation, Development and Support | EDBWN Software"/>
                <meta property="og:description" content="We specialize in facilitating organizations with their digital transformation, offering customized consultation sessions for your development teams and developing bespoke software projects."/>
                <meta property="og:image" content="https://edbwn.com/white.png"/>
                <meta property="og:url" content="https://edbwn.com"/>
            </Helmet>
            <section className="mb1">
                <Main/>
            </section>
            <section className="pt1">
                <Trusted/>
            </section>
            <section className="pt1">
                <Services/>
            </section>
            <section className="pt3">
                <Technologies/>
            </section>
            <section className="pt1">
                <Blogs/>
            </section>
        </>
    );
}