import React from "react";
import "./Services.scss";
import {useNavigate} from "react-router-dom";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Services() {
    const navigate = useNavigate();
    
    return (
        <div className="services-section">
            <div className="card">
                <div className="content animate">
                    <div className="service-card consultation fadeInUp--0">
                        <div className="service-image">
                            <img alt="Development Team Consultation" src="/consultative-sales.svg"/>
                        </div>
                        <h3>Consultation</h3>
                        <div className="description">
                            We'll assist your organization in enhancing, optimizing, and upskilling your Development team.
                        </div>
                        <button onClick={() => navigate("/consultation")}>
                            <span>Find Out More</span>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                    <div className="service-card development fadeInUp--1">
                        <div className="service-image">
                            <img alt="Bespoke Development" src="/development.svg"/>
                        </div>
                        <h3>Development</h3>
                        <div className="description">
                            We'll supply an IT resource or create a high-quality, tailored solution to meet your business needs.
                        </div>
                        <button onClick={() => navigate("/dev")}>
                            <span>Find Out More</span>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                    <div className="service-card support fadeInUp--2">
                        <div className="service-image">
                            <img alt="Live Support" src="/active-support.svg"/>
                        </div>
                        <h3>Support</h3>
                        <div className="description">
                            We offer ongoing support and maintenance for solutions developed by our team.
                        </div>
                        <button onClick={() => navigate("/dev")}>
                            <span>Find Out More</span>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}