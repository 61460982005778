import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function Animations() {
    const {pathname} = useLocation();

    let result = [];
    const getAnimations = (animation) => {
        result.push(animation);
        if (!animation.children) {
            return;
        }

        Array.from(animation.children).forEach(anim => {
            getAnimations(anim);
        });

        return result;
    }

    const animate = () => {
        const animations = document.getElementsByClassName("animate");

        Array.from(animations).forEach((anim, index) => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const animResult = getAnimations(entry.target);
                        result = [];
                        animResult.forEach(a => {
                            a.style.animationPlayState = "running";
                        });
                    }
                });
            }, {threshold: 0});

            observer.observe(anim)

        });
    }

    useEffect(() => {
        animate();
    }, [pathname]);

    return <></>;
}