import React from "react";
import "./Trusted.scss";

export default function Trusted() {
    return (
        <div className="trusted-section animate">
            <h2 className="fadeInUp--0">Trusted By</h2>
            <div className="trusted-container">
                <div className="card wellocks fadeInUp--1">
                    <div className="image single">
                        <img alt="Wellocks Logo" loading="lazy" className="wellocks" src="/wellocks-logo.png"/>
                    </div>
                </div>
                <div className="card wjfg fadeInUp--2">
                    <div className="image single">
                        <img alt="William Jackson Food Group Logo" loading="lazy" className="wellocks" src="/WJFG-Logo.png"/>
                    </div>
                </div>
                <div className="card belazu fadeInUp--3">
                    <div className="image single">
                        <img alt="Belazu Logo" loading="lazy" className="wellocks" src="/Belazu-logo.svg"/>
                    </div>
                </div>
                <div className="card testimony right fadeInUp--4">
                    <div className="image">
                        <img alt="James Tognola - WJFG Limited Testimonial" loading="lazy" src="/James.jpg"/>
                    </div>
                    <div className="content">
                        <div className="title">
                            James Tognola
                        </div>
                        <div className="sub-title">
                            Head of Ecommerce and Development at WJFG Limited
                        </div>
                        <div className="details">
                            Ed and EDBWN exceeded our expectations, delivering the smoothest project experience we've ever had. We thoroughly enjoyed working with Ed and eagerly anticipate collaborating with EDBWN Software again.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}