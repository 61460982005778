import React from "react";
import {Link, useNavigate} from "react-router-dom";
import "./DigitalTransformation.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPhone,
    faAnglesRight,
    faAnglesDown,
    faAnglesLeft,
    faRightLong,
    faDownLong, faRightLeft, faPlus
} from "@fortawesome/free-solid-svg-icons";

export default function DigitalTransformation() {
    const navigate = useNavigate();
    
    return (
        <div className="digital-transformation-container animate">
            <h2 className="fadeInUp--0">
                Digital Transformation Journey
            </h2>
            <div className="journey">
                <div className="call fadeInUp--1">
                    <button onClick={() => navigate("/contact")} className="primary book-call">
                        <span>Book a Call</span>
                        <FontAwesomeIcon icon={faPhone}/>
                    </button>
                </div>
                <div className="more-reading-section fadeInUp--2">
                    <i>To find out more about our software project journey please read one of our blogs <Link to="/blog/demystifying-digital-transformation">here</Link></i>
                </div>
                <div className="journey-item initial fadeInUp--3">
                    <div>
                        <div>Initial Call</div>
                        <div><i>~30 minute introduction call</i></div>
                    </div>
                    <div className="arrow right">
                        <FontAwesomeIcon icon={faRightLong}/>
                    </div>
                </div>
                <div className="journey-item consultation fadeInUp--4">
                    <div>
                        <div>Project Planning</div>
                        <div><i><span className="highlight">FREE</span> Project Planning</i></div>
                    </div>
                    <div className="arrow right">
                        <FontAwesomeIcon icon={faRightLong}/>
                    </div>
                </div>
                <div className="journey-item plan fadeInUp--5">
                    <div>
                        <div>Plan Agreement</div>
                        <div><i>Estimations & Costs Presented</i></div>
                    </div>
                    <div className="arrow down">
                        <FontAwesomeIcon icon={faDownLong}/>
                    </div>
                </div>
                <div className="journey-item dev fadeInUp--6">
                    <div>
                        <div>Development</div>
                        <div><i>Development Cycle</i></div>
                    </div>
                    <div className="arrow left">
                        <FontAwesomeIcon icon={faRightLeft}/>
                    </div>
                </div>
                <div className="journey-item qa-uat fadeInUp--7">
                    <div>
                        <div>QA & UA Testing</div>
                        <div><i>Quality Assurance & User Acceptance Testing</i></div>
                    </div>
                    <div className="arrow down">
                        <FontAwesomeIcon icon={faDownLong}/>
                    </div>
                </div>
                <div className="journey-item pr-support fadeInUp--8">
                    <div className="text">
                        <div>Production Release</div>
                        <div className="plus"><FontAwesomeIcon icon={faPlus}/></div>
                        <div>Support</div>
                    </div>
                    <div><i>First 3 Months <span className="highlight">FREE</span> support when 12 month support contract is signed</i></div>
                </div>
            </div>
        </div>
    );
}