import React, {useEffect, useState} from "react";
import "./Menu.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import {Link, useLocation} from "react-router-dom";

export default function Menu({scrolled = false}) {
    const [open, setOpen] = useState(false);
    const {pathname} = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    const links = [
        {name: "Home", uri: "/"},
        {name: "Development", uri: "/dev"},
        {name: "Consultation", uri: "/consultation"},
        {name: "About Us", uri: "/about-us"},
        {name: "Blog", uri: "/blog"}
    ]

    let classes = "";
    if (scrolled) {
        classes = ' scrolled';
    }

    const onOpen = (e) => {
        e.stopPropagation();
        setOpen(!open);
    }

    return (
        <>
            <div className={"full-width" + classes}>
                <ul>
                    {links.map(link => {
                        return <li key={link.name}><Link to={link.uri}>{link.name}</Link></li>;
                    })}
                </ul>
            </div>
            <div className={"mobile" + classes}>
                <div onClick={onOpen}>
                    {open ? <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon> : <FontAwesomeIcon icon={faBars}/>}
                    
                </div>
                <div className={open ? "content open" + classes : "content" + classes}>
                    <ul>
                        {links.map(link => {
                            return <li key={link.name}><Link to={link.uri}>{link.name}</Link></li>;
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}