import React, {useEffect} from "react";
import "./Blog.scss";
import {Link} from "react-router-dom";
import useBlogs from "../../hooks/useBlogs";
import Animations from "../../components/scroll-top/Animations";
import {Helmet} from "react-helmet";

export default function Blog() {
    const [blogs, loading] = useBlogs();
    
    if (loading) {
        return (
            <div className="blog-container">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    return (
        <div className="blog-container animate">
            <Helmet>
                <title>Professional insights and case studies | EDBWN Software</title>
                <meta name="description" content="New ideas, practical guides and inspiring case studies"/>
                <meta name="keywords" content="blog, blog page, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Professional insights and case studies | EDBWN Software"/>
                <meta property="og:description" content="New ideas, practical guides and inspiring case studies"/>
                <meta property="og:image" content="https://edbwn.com/white.png"/>
                <meta property="og:url" content="https://edbwn.com/blog"/>
            </Helmet>
            <div className="header mb1">
                <h1 className="fadeInUp--0">Blog</h1>
                <h3 className="fadeInUp--1">New ideas, practical guides and inspiring case studies</h3>
            </div>

            <div className="card-area">
                {blogs.map(blog => {
                    return (
                        <Link key={blog.id} to={`${blog.url}`}>
                            <div className="card blog-card has-action fadeInUp--2">
                                <div className="image">
                                    <img src={blog.imageSmall}/>
                                </div>
                                <div className="content">
                                    <div className="title">
                                        {blog.title}
                                    </div>
                                    <div className="sub-title">
                                        {new Date(blog.createdAt).toLocaleDateString("en-GB")} &bull; {blog.readTime}
                                    </div>
                                    <div className="details">
                                        {blog.description}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
            <Animations></Animations>
        </div>
    );
}