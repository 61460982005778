import React, {useEffect} from "react";
import "./NotFound.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function NotFound() {
    const navigate = useNavigate();
    
    return (
        <div className="not-found-container animate">
            <Helmet>
                <title>Page Not Found | EDBWN Software</title>
                <meta name="description" content="EDBWN Software Website Not Found Page"/>
            </Helmet>
            <section className="not-found-img fadeInUp--0">
                <img alt="Page Not Found" src="/undraw_page_not_found_re_e9o6.svg"/>
            </section>
            <section className="contact">
                <h2 className="fadeInUp--1">Not found what you looking for?</h2>
                <button onClick={() => navigate("/contact")} className="primary mt4 fadeInUp--2">
                    <span>Book a Call</span>
                    <FontAwesomeIcon icon={faPhone}/>
                </button>
            </section>
        </div>
    );
}