import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import Header from "../components/header/Header";
import "./Layout.scss";
import Footer from "../components/footer/Footer";

export default function Layout() {
    const [scrollClass, setScrollClass] = useState("");

    const onScroll = () => {
        if (window.scrollY > 50) {
            setScrollClass("scrolled");
        } else {
            setScrollClass("");
        }
    }

    useEffect(() => {
        onScroll();
        window.addEventListener("scroll", onScroll);

        return function cleanup() {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    return (
        <>
            <header className={scrollClass}>
                <Header scrolled={scrollClass === "scrolled"}/>
            </header>
            <main>
                <Outlet/>
            </main>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
